(function() {
  angular.module('EntrakV5').controller('occupiedAreaController', occupiedAreaController);

  function occupiedAreaController($scope, $timeout, $rootScope, Service, $http, Api, KEY, APIKEY, EMS_URL, $state, $stateParams) {
    console.log('occupiedAreaController');

    var caller = Api.createApiCaller();
    
    $scope.btnStatus = {};
    $rootScope.hideTabs = false;

    $scope.tenantMap = null;
    //tmp
    $scope.tenantId = null;
    $scope.povMap = null;
    $scope.occupiedAreas = null;
    $scope.historyData = null;
    $scope.occupiedAreaData = null;

    $scope.bindPOVLbl = Service.translate("oa.bindPOV");
    var nameSorter = Service.getSorterIgnoreCase();

    $rootScope.loadingPage++;
    caller.call(Api.getDisplayTenants(false, false)).then(function(tenants) {
      tenants.sort(nameSorter);

      $scope.tenantDropdown.setDataSource(new kendo.data.DataSource({
        data: tenants
      }));

      $scope.tenantMap = Service.arrayToMap(tenants, "id");
      if (tenants.length){
        $scope.tenantId = tenants[0].id;
        $scope.loadTenantPovs($scope.tenantId);
        $scope.loadTenantOccupiedAreas($scope.tenantId);
      }

      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.loadingPage--;
      alert(Service.translate("error.generalGetDataFail"));
    });

    $scope.loadTenantPovs = function(tenantId) {
      if (tenantId) {
        var tenant = $scope.tenantMap[tenantId];
        if (tenant.povs){
          $scope.povMap = Service.arrayToMap(tenant.povs, "id");
          $scope.povDropdown.setDataSource(new kendo.data.DataSource({
            data: tenant.povs
          }));
        } else {
          $rootScope.loadingPage++;
          caller.call(Api.getTenantPov(tenantId)).then(function(povs) {
            povs.sort(nameSorter);
            tenant.povs = povs;
            $scope.povMap = Service.arrayToMap(tenant.povs, "id");
            $scope.povDropdown.setDataSource(new kendo.data.DataSource({
              data: povs
            }));
            $rootScope.loadingPage--;
          }, function(err) {
            if (err === KEY.ignore)
              return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
          });
        }
      }
    }

    $scope.loadTenantOccupiedAreas = function(tenantId) {
      $scope.occupiedAreas = [];
      if (tenantId) {
        $rootScope.loadingPage++;
        caller.call(Api.getOccupiedAreas(tenantId)).then(function(oas) {
          oas.sort(nameSorter);
          $scope.occupiedAreas = oas;
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          alert(Service.translate("error.generalGetDataFail"));
        });
      }
    }

    $scope.tenantDropdownOpt = {
      dataSource: [],
      dataTextField: "name",
      dataValueField: "id",
      autoWidth: true,
      change: function() {
        $scope.loadTenantPovs($scope.tenantId);
        $scope.loadTenantOccupiedAreas($scope.tenantId);
      }
    }

    $scope.clickUpload = function(){
      $('#occupiedArea #dataFileChooser').click();
    }
    $scope.onSelectFile = function(inputFile){
      if (!window.File || !window.FileReader){
        console.log("not supported");
        return;
      }

      if (!inputFile.files || !inputFile.files[0])
        return;

      var f = inputFile.files[0];
      if (f.size > 10485760 || f.fileSize > 10485760){//10MB limit
        alert("uploadCtrl.max10Mb");
        return;
      }

      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var fileReader = new FileReader();
      fileReader.onload = function(e){
        caller.call(Api.uploadOccupiedAreas($scope.tenantId, f)).then(function(h) {
          $rootScope.loadingPage--;
          $scope.btnStatus.saving = false;
          $scope.loadTenantOccupiedAreas($scope.tenantId);
        }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        alert(err.message);
      });
      }
      fileReader.readAsDataURL(f);

      inputFile.value = null;
    }

    $scope.areaHistoryWinOpt = {
      title: Service.translate("oa.areaRecord"),
      width: "450px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.saving = false;
        });
      }
    }
    $scope.pickerOpt = {}
    $scope.showAreaHistory = function(oa) {
      var id = oa.id;
      $rootScope.loadingPage++;
      $scope.btnStatus.history = true;
      $scope.historyData = { id: id, showAdd: false };
      caller.call(Api.getOccupiedAreaHistory(id)).then(function(h) {
        $scope.historyData.records = h;
        $rootScope.loadingPage--;
        $scope.btnStatus.history = false;
        setTimeout(function() {
          $scope.areaHistoryWin.open().center();
        });
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.history = false;
        alert(Service.translate("error.generalGetDataFail"));
      });
    }
    $scope.getLatestHistoryRecord = function(){
      if ($scope.historyData.records && $scope.historyData.records.length){
        var latest = $scope.historyData.records[0];
        $scope.historyData.records.forEach(function(r){
          if (r.effectiveDate > latest.effectiveDate)
            latest = r;
        });
        return latest;
      }
    }
    $scope.deleteOccupiedAreaEffectiveDate = function(history){
      var id = history.id;
      var occupiedAreaId = $scope.historyData.id;
      $rootScope.deletePopup.show("oa.deleteOccupiedAreaEffectiveDate", "oa.deleteOccupiedAreaEffectiveDateDesc", Service.dateFmt(history.effectiveDate), function() {
        caller.call(Api.deleteOccupiedAreaEffectiveDate(id)).then(function(res) {
          Service.deleteArrItem($scope.historyData.records, id);
          var oa = Service.getArrItem($scope.occupiedAreas, occupiedAreaId);
          if (oa && oa.latestArea && oa.latestArea.id === id)
            oa.latestArea = $scope.getLatestHistoryRecord();
          $rootScope.deletePopup.close();
        });
      }, function(err) {
        if (err === KEY.ignore)
          return;
        alert(Service.translate("error.generalDeleteFail"));
      });
    }
    $scope._confirmAddAreaHistory = function(latestRecord, newTimestamp){
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var oa = Service.getArrItem($scope.occupiedAreas, $scope.historyData.id);
      caller.call(Api.createOccupiedAreaEffectiveDate($scope.historyData.id, $scope.historyData.newArea, newTimestamp)).then(function(res) {
        $scope.historyData.records.push(res);
        oa.latestArea = latestRecord && latestRecord.effectiveDate > res.effectiveDate ? latestRecord : res;
        $scope.historyData.showAdd = false;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.btnStatus.saving = false;
        $rootScope.loadingPage--;
        alert(err.message ? err.message :　Service.translate("error.generalUpdate"));
      });
    }
    $scope.confirmAddAreaHistory = function(){
      var newTimestamp = Service.getUnixTimestamp($scope.historyData.newDate);
      var latestRecord = $scope.getLatestHistoryRecord();
      if (latestRecord && latestRecord.effectiveDate >= newTimestamp){
        $rootScope.confirmPopup.show("oa.confirmAddArea", "oa.confirmAddOldAreaDesc", Service.dateFmt(newTimestamp), function() {
          $scope._confirmAddAreaHistory(latestRecord, newTimestamp);
          $rootScope.confirmPopup.close();
        });
      } else {
        $scope._confirmAddAreaHistory(latestRecord, newTimestamp);
      }
    }
    $scope.showAddHistory = function(){
      $scope.historyData.showAdd = true;
      $scope.historyData.newDate = new Date();
      $scope.historyData.newArea = 0;
      $scope.historyData.newDate.setHours(0,0,0,0);
    }

    $scope.editOccupiedAreaWinOpt = {
      title: Service.translate("oa.areaRecord"),
      width: "620px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.saving = false;
        });
      }
    }
    $scope.showEditOccupiedArea = function(oa) {
      if (oa) {
        $scope.editOccupiedAreaWin.title(Service.translate("oa.editOccupiedArea"));
        $scope.occupiedAreaData = { id: oa.id, name: oa.name, remark: oa.remark, tenantId: oa.tenantId };
      } else {
        $scope.editOccupiedAreaWin.title(Service.translate("oa.createOccupiedArea"));
        $scope.occupiedAreaData = { name: "", remark: "", tenantId: $scope.tenantId };
      }
      setTimeout(function() {
        $scope.editOccupiedAreaWin.open().center();
      });
    }
    $scope.confirmEditOccupiedArea = function(){
      $rootScope.loadingPage++;
      var errHandler = function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        alert(err.message ? err.message :　Service.translate("error.generalUpdate"));
      };
      var successHandler = function(res) {
        var oa = Service.getArrItem($scope.occupiedAreas, res.id);
        if (oa) {
          for (var k in res) {
            if (res.hasOwnProperty(k))
              oa[k] = res[k];
          }
        } else {
          $scope.occupiedAreas.push(res);
          res.latestArea = null;
          res.povId = null;
        }
        $rootScope.loadingPage--;
        $scope.editOccupiedAreaWin.close();
      }
      if ($scope.occupiedAreaData.id) {
        caller.call(Api.updateOccupiedArea($scope.occupiedAreaData.id, $scope.occupiedAreaData.name, $scope.occupiedAreaData.remark)).then(successHandler, errHandler);
      } else {
        caller.call(Api.createOccupiedArea($scope.occupiedAreaData.name, $scope.occupiedAreaData.tenantId, $scope.occupiedAreaData.remark)).then(successHandler, errHandler);
      }
    }

    $scope.deleteOccupiedArea = function(){
      var id = $scope.occupiedAreaData.id;
      $rootScope.deletePopup.show("oa.deleteOccupiedArea", "oa.deleteOccupiedAreaDesc", $scope.occupiedAreaData.name, function() {
        caller.call(Api.deleteOccupiedArea(id)).then(function(res) {
          Service.deleteArrItem($scope.occupiedAreas, id);
          $rootScope.deletePopup.close();
          $scope.editOccupiedAreaWin.close();
        });
      }, function(err) {
        if (err === KEY.ignore)
          return;
        alert(Service.translate("error.generalDeleteFail"));
      });
    }

    $scope.bindPOVData = {};
    $scope.bindPOVWinOpt = {
      title: Service.translate("oa.bindPOV"),
      width: "620px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.saving = false;
        });
      }
    }
    $scope.povDropdownOpt = {
      dataSource: [],
      dataTextField: "name",
      dataValueField: "id",
      autoWidth: true,
    }
    $scope.showBindPOV = function(oa) {
      $scope.bindPOVData = { id: oa.id, povId: oa.povId };
      setTimeout(function() {
        $scope.bindPOVWin.open().center();
      });
    }
    $scope.confirmBindPOV = function(){
      var id = $scope.bindPOVData.id;
      var povId = $scope.bindPOVData.povId;
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      caller.call(Api.bindOccupiedAreaToPov(id, povId)).then(function(res) {
        var oa = Service.getArrItem($scope.occupiedAreas, id);
        if (oa) {
          oa.povId = povId;
        }
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.bindPOVWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.btnStatus.saving = false;
        $rootScope.loadingPage--;
        alert(err.message ? err.message :　Service.translate("error.generalUpdate"));
      });
    }

    $scope.$on('$destroy', function() {
      console.log("occupiedAreaController destroy");
      caller.cancel();
    });
  }
})();